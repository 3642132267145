import React from 'react';
import { Modal } from 'react-bootstrap';

const MindMapModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>About Mind Map</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Mind Map is a new feature that helps visualize relationships between articles 
          and research topics. It creates an interactive diagram showing how different 
          concepts and papers are connected. <a target="_blank" href="https://blog.tripdatabase.com/2025/02/07/mind-maps-now-live/">See more</a>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default MindMapModal; 