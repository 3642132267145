import React, { useState, useEffect } from "react"
import { Spinner } from "react-bootstrap";
import axios from "axios";

/*
import { Markmap } from 'markmap-view';
import { transformer } from './markmap';
*/

const optionsValue = `---
markmap:
  pan: false
---
`

const LambdaUrl=`https://qbcu5ndniur6ffubhverqxht3q0fxwdp.lambda-url.eu-west-2.on.aws/`;

export function MindMap( { keywords, onClose } ) {

    const [submitting, setSubmitting] = useState(false);

    const [error, setError]=useState();

    const [markdown, setMarkdown] = useState();

    const submit = () => {

        setError(undefined);
        setSubmitting(true);
        setMarkdown(undefined);

        try {

          
          axios.get(`${LambdaUrl}?search=${encodeURIComponent(keywords)}` )
          .then( response => {

            if (response.data?.markdown) {

              const lines = response.data?.markdown.split('\n');

              const linkedLines = lines.map( (line,index) => {

                let start=0;
                while (line[start]===' ' && start<line.length) {
                  start++;
                }
                
                const spacePos=line.indexOf(' ',start);

                if (spacePos!=-1) {
                  let head=line.substring(0,spacePos);
                  const tail=line.substring(spacePos);

                  let display=tail;
                  let search=tail;

                  const openBracketPos=tail.lastIndexOf('(');
                  if (openBracketPos!==-1) {
                    display=tail.substring(0,openBracketPos).replace(/\#+/ig,'').trim();
                    const closeBracketPos=tail.lastIndexOf(')');
                    search=tail.substring(openBracketPos+1, closeBracketPos===-1? undefined:closeBracketPos);
                  }

                  display=display.replace(/^- /,'').replace(/^[\*]+/,'').trim();
                  
                  const url=`/Searchresult?search_type=standard&criteria=${encodeURIComponent(search)}`;

                  const adapted= `${head} [${display}](${url})`

                  ///console.log(index,line);
                  ///console.log('=>',adapted);

                  return adapted
                } else {

                  return line;
                }
              });

              const newValue = `${optionsValue} ${linkedLines.join('\n')}`

              setMarkdown(newValue);
              window.markmap.autoLoader.renderAll();
              setSubmitting(false);

            }
          })
          .catch( e => {
            console.error(e);
            setSubmitting(false);
            setError(e);
          });
          

        }
        catch (e) {
          console.error(e);
          setSubmitting(false);
          setError(e);
        }

    }

    const close = () => {
        setMarkdown(undefined);
    }

    useEffect(() => {

        if (keywords) {
          submit();
        }
    }, [keywords]);

    if (!keywords) {
      return <>No keywords</>
    }

    return <div className="mindmap">
        
        <div className="mindmap-results">

            { error && <div className="error">Sorry, an error occurred when generating Mind Map :/</div> }

            {submitting ? <div className="markmap">Generating Mind Map for "{keywords.trim()}" <br /><br /><Spinner animation="border" variant="success" /></div> : <>

                {markdown && 

                    <>
                      <h3>Mind Map for "{keywords}" <button onClick={ onClose } className="button-close">&times;</button> </h3>
                        <div className="markmap">

                            <script type="text/template" >
                                {markdown}
                            </script>
                        </div>
                    </>
                }

            </>}


        </div>
    </div>

}