import React, { Component, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { Tab, Tabs, Form, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import _ from "underscore";
import Autosuggest from "react-autosuggest";
import "../assets/css/Autosuggest.css";
import RecentSearch from "./RecentSearch";
import RecentAdvSearch from "./RecentAdvSearch";
const { sanatizeBooleans, addPrefix } = require("./sanatizeBooleans");
const axios = require("axios");
const store = require("store");
const qsObj = require("query-string");

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

function getSuggestionValue(suggestion) {
  return suggestion.text;
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.text}</span>;
}

const Search = (props) => {
  const qs = qsObj.parse(props.location.search);
  var latestRequest = null;
  var k = "standardsearch";
  if (window.location.pathname == "/Searchresult") {
    k = _.isUndefined(store.get("key")) ? "standardsearch" : store.get("key");
  }
  const [key, setKey] = useState(k);
  const [suggestions, setSuggestions] = useState(getSuggestions(""));

  const [value, setValue] = useState(
    !_.isUndefined(qs.criteria) && qs.search_type == "standard"
      ? qs.criteria
      : ""
  );
  const [criteria, setCriteria] = useState(
    !_.isUndefined(qs.criteria) && qs.search_type == "standard"
      ? qs.criteria
      : ""
  );
  //PICO search initialization
  const [population, setPopulation] = useState(
    !_.isUndefined(qs.criteria) && qs.search_type == "pico" ? qs.criteria : ""
  );
  const [comparison, setComparison] = useState(
    !_.isUndefined(qs.comparison) ? qs.comparison : ""
  );
  const [intervention, setIntervention] = useState(
    !_.isUndefined(qs.intervention) ? qs.intervention : ""
  );
  const [outcome, setOutcome] = useState(
    !_.isUndefined(qs.outcome) ? qs.outcome : ""
  );

  //Advanced Search initialization
  const [allwords, setAllwords] = useState(
    !_.isUndefined(qs.criteria) && qs.search_type == "advanced"
      ? qs.criteria
      : ""
  );
  const [anywords, setAnywords] = useState(
    !_.isUndefined(qs.anywords) ? qs.anywords : ""
  );
  const [exactphrase, setExactphrase] = useState(
    !_.isUndefined(qs.exactphrase) ? qs.exactphrase : ""
  );
  const [excludingwords, setExcludingwords] = useState(
    !_.isUndefined(qs.excludingwords) ? qs.excludingwords : ""
  );
  const [advsearchbox, setAdvSearchbox] = useState(
    !_.isUndefined(qs.criteria) && qs.search_type == "advanced2"
      ? qs.criteria
      : ""
  );
  const [advfrom, setAdvfrom] = useState(
    !_.isUndefined(qs.advfrom) ? qs.advfrom : ""
  );
  const [advto, setAdvto] = useState(!_.isUndefined(qs.advto) ? qs.advto : "");
  const [advfromdate, setAdvfromdate] = useState(
    !_.isUndefined(qs.from_date) ? qs.from_date : ""
  );
  const [advtodate, setAdvtodate] = useState(
    !_.isUndefined(qs.to_date) ? qs.to_date : ""
  );

  const [proximity, setProximity] = useState(
    !_.isUndefined(qs.proximity) ? qs.proximity : ""
  );
  const [proximity2, setProximity2] = useState(
    !_.isUndefined(qs.proximity2) ? qs.proximity2 : ""
  );
  const [advsearchcondition, setAdvSearchCondition] = useState(
    !_.isUndefined(qs.advsearchcondition) ? qs.advsearchcondition : ""
  );
  const [anywordsfield, setAnywordsField] = useState(
    !_.isUndefined(qs.anywordsfield) ? qs.anywordsfield : "tb"
  );
  const [allwordsfield, setAllwordsField] = useState(
    !_.isUndefined(qs.allwordsfield) ? qs.allwordsfield : "tb"
  );
  const [exactphrasefield, setExactphraseField] = useState(
    !_.isUndefined(qs.exactphrasefield) ? qs.exactphrasefield : "tb"
  );
  const [excludingwordsfield, setExcludingwordsField] = useState(
    !_.isUndefined(qs.excludingwordsfield) ? qs.excludingwordsfield : "tb"
  );
  const [advsearchboxfield, setAdvSearchboxField] = useState(
    !_.isUndefined(qs.advsearchboxfield) ? qs.advsearchboxfield : ""
  );
  const [recent, setRecent] = useState(
    !_.isUndefined(qs.criteria) && qs.search_type == "recent" ? qs.criteria : ""
  );
  const [advsearchconditionbtn, setAdvSearchConditionbtn] = useState("");
  // const sanatizeBooleansAdv = (param) => {
  //   return param
  //     .toLowerCase()
  //     .replace(/ not\(/gi, " NOT (")
  //     .replace(/ or\(/gi, " OR (")
  //     .replace(/ and\(/gi, " AND (")
  //     .replace(/\)not /gi, ") NOT ")
  //     .replace(/\)or /gi, ") OR ")
  //     .replace(/\)and /gi, ") AND ")
  //     .replace(/ or /gi, " OR ")
  //     .replace(/ not /gi, " NOT ")
  //     .replace(/ and /gi, " AND ")
  //     .replace(/“/gi, '"')
  //     .replace(/”/gi, '"')
  //     .trim();
  // };
  const removeBooleans = (param) => {
    return param
      .toLowerCase()
      .replace(/\(/gi, "")
      .replace(/\)/gi, "")
      .replace(/ or /gi, " ")
      .replace(/[0-9]/g, "")
      .replace(/ not /gi, " ")
      .replace(/ NOT /gi, " ")
      .replace(/ AND /gi, " ")
      .replace(/ and /gi, " ")
      .replace(/~/gi, "")
      .replace(/title:/gi, "")
      .replace(/"/gi, "")
      .replace(/,/gi, " ")
      .replace(/'/gi, "")
      .trim();
  };

  //Handle standard submit
  const handleSubmit = (e) => {
    e.preventDefault();
    store.set("key", key);
    var search_criteria = {
      criteria: sanatizeBooleans(criteria),
      search_type: "standard",
    };
    saveHistory(search_criteria);
    props.history.push({
      pathname: "/Searchresult",
      search:
        "?criteria=" + sanatizeBooleans(criteria) + "&search_type=standard",
      state: search_criteria,
    });
  };

  const handleSuggestionSelected = (e) => {
    e.preventDefault();
    store.set("key", key);
    var search_criteria = {
      criteria: sanatizeBooleans(criteria),
      search_type: "standard",
    };
    saveHistory(search_criteria);
    props.history.push({
      pathname: "/Searchresult",
      search:
        "?criteria=" + sanatizeBooleans(criteria) + "&search_type=standard",
      state: search_criteria,
    });
  };

  //Handle Pico submit
  const handlePicoSubmit = (e) => {
    e.preventDefault();
    store.set("key", key);
    var search_criteria = {
      criteria: sanatizeBooleans(population),
      intervention: sanatizeBooleans(intervention),
      outcome: sanatizeBooleans(outcome),
      comparison: sanatizeBooleans(comparison),
      search_type: "pico",
    };
    saveHistory(search_criteria);
    props.history.push({
      pathname: "/Searchresult",
      search:
        "?criteria=" +
        sanatizeBooleans(population) +
        "&intervention=" +
        sanatizeBooleans(intervention) +
        "&comparison=" +
        sanatizeBooleans(comparison) +
        "&outcome=" +
        sanatizeBooleans(outcome) +
        "&search_type=pico",
      state: search_criteria,
    });
  };

  //Handle Advanced submit
  const handleAdvancedSubmit = (e) => {
    e.preventDefault();
    store.set("key", key);
    var search_criteria = {
      criteria: sanatizeBooleans(allwords),
      anywords: sanatizeBooleans(anywords),
      exactphrase: sanatizeBooleans(exactphrase),
      excludingwords: sanatizeBooleans(excludingwords),
      advfrom: advfrom,
      advto: advto,
      proximity: proximity,
      allwordsfield: allwordsfield,
      anywordsfield: anywordsfield,
      exactphrasefield: exactphrasefield,
      excludingwordsfield: excludingwordsfield,
      search_type: "advanced",
    };
    saveHistory(search_criteria);
    props.history.push({
      pathname: "/Searchresult",
      search:
        "?criteria=" +
        sanatizeBooleans(allwords) +
        "&anywords=" +
        sanatizeBooleans(anywords) +
        "&exactphrase=" +
        sanatizeBooleans(exactphrase) +
        "&excludingwords=" +
        sanatizeBooleans(excludingwords) +
        "&advfrom=" +
        advfrom +
        "&advto=" +
        advto +
        "&proximity=" +
        proximity +
        "&allwordsfield=" +
        allwordsfield +
        "&anywordsfield=" +
        anywordsfield +
        "&exactphrasefield=" +
        exactphrasefield +
        "&excludingwordsfield=" +
        excludingwordsfield +
        "&search_type=advanced",
      state: search_criteria,
    });
  };

  const handleAdvanced2Submit = (e, filter = false) => {
    e.preventDefault();
    let qry = "";
    qry = prepareQueryConditions();

    store.set("key", key);
    var search_criteria = {
      criteria: qry,
      proximity2: proximity2,
      advsearchcondition: advsearchcondition,
      advsearchfield: advsearchboxfield,
      search_type: "advanced2",
    };
    // if (filter) {
    //   search_criteria["from_date"] = advfromdate;
    //   search_criteria["to_date"] = advtodate;
    // }
    if (!filter) saveHistoryAdv(search_criteria);
    props.history.push({
      pathname: "/Searchresult",
      search:
        "?criteria=" +
        sanatizeBooleans(qry) +
        // (advfromdate && filter ? "&from_date=" + advfromdate : "") +
        "&advsearchboxfield=" +
        advsearchboxfield +
        "&search_type=advanced2",
      state: search_criteria,
    });
  };

  //Handle Recent submit
  // const handleRecentSubmit = (e) => {
  //   var get_recent = getHistory().reverse();

  //   var recent_array = recent.split(" ");
  //   let j = 0;
  //   let new_qs = "";

  //   for (let l of recent_array) {
  //     if (l.includes("#")) {
  //       var idx = l.replace(/\D/g, "");
  //       if (!_.isUndefined(get_recent[idx - 1])) {
  //         new_qs += prepareRecentQuery(get_recent[idx - 1]);
  //       }
  //     } else {
  //       new_qs += " " + l + " ";
  //     }

  //     j++;
  //   }
  //   store.set("key", "standardsearch");
  //   var search_criteria = {
  //     criteria: sanatizeBooleans(new_qs),
  //     search_type: "standard",
  //   };
  //   saveHistory(search_criteria);

  //   props.history.push({
  //     pathname: "/Searchresult",
  //     search: "?criteria=" + new_qs + "&search_type=standard",
  //     state: search_criteria,
  //   });
  //   e.preventDefault();
  // };
  var recent_params_obj = {};
  const preparePICOQuery = (qs) => {
    let str = "";
    switch (1) {
      case 1:
        str =
          " title:" +
          qs.criteria +
          " title:" +
          qs.intervention +
          " title:" +
          qs.outcome +
          " title:" +
          qs.comparison;
        break;
      case 2:
        str =
          " title:" +
          qs.criteria +
          " title:" +
          qs.intervention +
          " title:" +
          qs.outcome +
          " " +
          qs.comparison;
        break;
      case 3:
        str =
          " title:" +
          qs.criteria +
          " title:" +
          qs.intervention +
          " " +
          qs.outcome +
          " " +
          qs.comparison;
        break;
      case 4:
        str =
          " title:" +
          qs.criteria +
          " " +
          qs.intervention +
          " " +
          qs.outcome +
          " " +
          qs.comparison;
        break;
      case 5:
        str =
          " " +
          qs.criteria +
          " " +
          qs.intervention +
          " " +
          qs.outcome +
          " " +
          qs.comparison;
        break;
    }
    return str;
  };

  const prepareRecentQuery = (qs) => {
    var str = "";
    var qry = "";
    if (!_.isUndefined(qs.search_type)) {
      switch (qs.search_type) {
        case "standard":
          if (qs.criteria) {
            str = qs.criteria;
          }
          break;
        case "pico":
          str = preparePICOQuery(qs);

          break;
        case "advanced":
          // arr.map(el => 'images/' + el)
          if (qs.criteria) {
            qry = qs.criteria.split(" ");
            str +=
              "(" +
              (qs.allwordsfield == "t"
                ? qry.map((el) => "title:" + el).join(" ")
                : qry.map((el) => el).join(" ")) +
              ")";
          }
          if (qs.anywords) {
            qry = qs.anywords.split(" ");
            str +=
              " (" +
              (qs.anywordsfield == "t"
                ? qry
                    .map((el) => "title:" + el)
                    .reduce((prev, curr) => prev + " OR " + curr)
                : qry
                    .map((el) => el)
                    .reduce((prev, curr) => prev + " OR " + curr)) +
              ")";
          }
          if (qs.exactphrase) {
            qry = '"' + qs.exactphrase + '"';
            str +=
              " (" + (qs.anywordsfield == "t" ? "title:" + qry : qry) + ")";
          }
          if (qs.excludingwords) {
            qry = qs.excludingwords.split(" ");
            str +=
              " NOT (" +
              (qs.excludingwordsfield == "t"
                ? qry.map((el) => "title:" + el).join(" ")
                : qry.map((el) => el).join(" ")) +
              ")";
          }

          //Additional filters

          if (qs.advfrom) {
            recent_params_obj["from_date"] = qs.advfrom;
          }
          if (qs.advto) {
            recent_params_obj["to_date"] = qs.advto;
          }

          break;
        case "advanced2":
          if (!_.isUndefined(qs.criteria) && _.isString(qs.criteria)) {
            qry = qs["criteria"].split(" ");
            str +=
              "(" +
              (qs.advsearchfield == "t" && !qs.criteria.includes("title:")
                ? qry.map((el) => "title:" + el).join(" ")
                : qry.map((el) => el).join(" ")) +
              ")";
          }

          break;
      }
    }

    return str;
  };

  const saveHistory = (search) => {
    var existing = [];
    existing = getHistory();

    if (_.size(existing) > 29) {
      existing.pop();
    }
    existing.push(search);
    store.set("recent", existing);
  };

  const getHistory = () => {
    var existingHistory = "";
    if (!_.isUndefined(store.get("recent"))) {
      existingHistory = store.get("recent");
    }
    return _.size(existingHistory) > 0 ? existingHistory : [];
  };
  /**
   * Advanced Search
   * @param {*} search
   */
  const saveHistoryAdv = (search) => {
    var existingadv = [];
    existingadv = getHistoryAdv();

    if (_.size(existingadv) > 29) {
      existingadv.reverse().pop();
      existingadv.reverse();
    }
    existingadv.push(search);
    store.set("advanced", existingadv);
  };
  /**
   * getHistoryAdvanced Search
   * @returns
   */
  const getHistoryAdv = () => {
    var existingHistoryAdv = "";
    if (!_.isUndefined(store.get("advanced"))) {
      existingHistoryAdv = store.get("advanced");
    }
    return _.size(existingHistoryAdv) > 0 ? existingHistoryAdv : [];
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  async function fetchSuggestions(keywords) {
    const thisRequest = (latestRequest = axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/Searchsuggestions"
          : "/Searchsuggestions",
        {
          params: { keywords: keywords },
          withCredentials: true,
        }
      )

      .then((res) => {
        // If this is true there's a newer request happening, stop everything
        if (thisRequest !== latestRequest) {
          return;
        }

        // If this is executed then it's the latest request

        if (res.status == 200) {
          return res.data;
        } else return [];
      })
      .catch((error) => {
        console.log("SEARCH ERROR");
        //console.log(error);
      }));
    return thisRequest;
  }
  async function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === "") {
      return [];
    }

    return await fetchSuggestions(escapedValue);
  }
  const onChangeSuggestions = async (event, { newValue, method }) => {
    setValue(newValue);
    setCriteria(newValue);
  };
  const onSuggestionsFetchRequested = async ({ value }) => {
    setSuggestions(await getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  var current_path = window.location.pathname;

  const inputProps = {
    placeholder: "Search terms",
    value,
    onChange: onChangeSuggestions,
    id: "formStandardSearchtext",
    className: "form-control react-autosuggest__input",
    role: "search",
    autoFocus: current_path == "/Home" || current_path == "/" ? true : false,
    required: true,
  };

  const prepareQueryConditions = () => {
    let str = sanatizeBooleans(advsearchbox);
    let qry = str.replace(/title:\(/gi, "");
    if (advsearchbox && advsearchcondition) {
      qry = advsearchcondition !== "phrase" ? removeBooleans(qry) : qry;
      qry = qry.split(" ");
      let i = 0;
      switch (advsearchcondition) {
        case "OR":
          str =
            "(" +
            (advsearchboxfield === "t"
              ? qry
                  .map((el) => "title:" + el)
                  .reduce((prev, curr) => prev + " OR " + curr)
              : qry
                  .map((el) => el)
                  .reduce((prev, curr) => prev + " OR " + curr)) +
            ")";
          break;
        case "AND":
          str =
            "(" +
            (advsearchboxfield == "t"
              ? qry
                  .map((el) => "title:" + el)
                  .reduce((prev, curr) => prev + " AND " + curr)
              : qry
                  .map((el) => el)
                  .reduce((prev, curr) => prev + " AND " + curr)) +
            ")";
          break;
        case "NOT":
          str =
            " NOT (" +
            (advsearchboxfield == "t"
              ? qry
                  .map((el) => "title:" + el)
                  .reduce((prev, curr) => prev + " AND " + curr)
              : qry
                  .map((el) => el)
                  .reduce((prev, curr) => prev + " AND " + curr)) +
            ")";
          break;
        case "phrase":
          qry = qry.join(" ");
          qry = qry.replace(/title:/gi, "");
          qry = qry.replace(/"/g, "");
          qry = '"' + qry.toLowerCase() + '"';
          str = advsearchboxfield === "t" ? "title:" + qry : qry;
          break;
      }
    }

    if (!_.isEmpty(proximity2) && advsearchcondition === "") {
      str = removeBooleans(str);
      str = str.replace(/title:/gi, "");
      str = '"' + str + '"~' + proximity2;
      str = advsearchboxfield === "t" ? "title:" + str : str;
    }
    if (
      _.isEmpty(advsearchcondition) &&
      _.isEmpty(proximity2) &&
      advsearchboxfield == "t"
    ) {
      str = str.replace(/title:/gi, "");
      str = addPrefix(str, "title:");
      // let str2 = str.replace(/\(/gi, "").replace(/\)/gi, "");
      // str2 = str2.split(" ");

      // str = str2
      //   .map((el) =>
      //     el.toLowerCase() === "and" ||
      //     el.toLowerCase() === "or" ||
      //     el.toLowerCase() === "not"
      //       ? el
      //       : "title:" + el.replace(/title:/gi, "")
      //   )
      //   .join(" ");
    }
    //tokenize #
    if (str.includes("#")) {
      let adv_history = getHistoryAdv();
      //do something
      var adv_array = str.split(" ");
      // let j = 0;
      let new_qs = "";
      for (let l of adv_array) {
        if (l.includes("#")) {
          var idx = l.replace(/\D/g, "");
          if (!_.isUndefined(adv_history[idx - 1])) {
            new_qs += prepareRecentQuery(adv_history[idx - 1]);
          }
        } else {
          new_qs += " " + l + " ";
        }
        // j++;
      }
      // console.log("generate qs", str, new_qs);
      str = new_qs;
    }
    // if (advfrom) {
    //   str += "&from_date=" + advfrom;
    // }
    // if (advto) {
    //   str += "&to_date=" + advto;
    // }
    return str;
  };
  return (
    <div className="site-search">
      <Tabs
        id="search-form"
        activeKey={key}
        onSelect={(k) => {
          setKey(k);
        }}
      >
        <Tab
          eventKey="standardsearch"
          variant="pills"
          title="  Search  "
          role="tablist"
        >
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formStandardSearchtext">
              <InputGroup>
                <Autosuggest
                  suggestions={_.isArray(suggestions) ? suggestions : []}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  onSuggestionSelected={(e, data) => {
                    setCriteria(data.suggestionValue);
                    store.set("key", key);
                    var search_criteria = {
                      criteria: sanatizeBooleans(data.suggestionValue),
                      search_type: "standard",
                    };
                    saveHistory(search_criteria);
                    props.history.push({
                      pathname: "/Searchresult",
                      search:
                        "?criteria=" +
                        sanatizeBooleans(data.suggestionValue) +
                        "&search_type=standard",
                      state: search_criteria,
                    });
                  }}
                />
                <InputGroup.Append>
                  <Button type="submit">
                    <i className="fa fa-search"></i> Search
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Form>
        </Tab>
        <Tab eventKey="picosearch" title="  PICO  ">
          <form className="" onSubmit={handlePicoSubmit}>
            <div className="form-group-inputs">
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="population">
                      Population &nbsp;&nbsp;
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="population"
                    placeholder="e.g. diabetics"
                    value={population}
                    onChange={(e) => setPopulation(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="intervention">
                      Intervention&nbsp;
                    </span>
                  </div>

                  <input
                    name="intervention"
                    placeholder="e.g. a treatment, diagnostic test"
                    type="text"
                    id="formPicoIntervention"
                    className="form-control"
                    value={intervention}
                    onChange={(e) => setIntervention(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="comparison">
                      Comparison&nbsp;
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="comparison"
                    placeholder="any comparison intervention"
                    value={comparison}
                    onChange={(e) => setComparison(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="outcome">
                      Outcome&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="outcome"
                    placeholder="e.g. mortality, blood pressure"
                    value={outcome}
                    onChange={(e) => setOutcome(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group-submit">
              <button type="submit" className="btn btn-primary">
                <i className="fa fa-search"></i> Search
              </button>
            </div>
          </form>
          <div>
            <i className="fa fa-info-circle text-info"></i>
            <i>
              PICO search is not designed for exhaustive searches,{" "}
              <a
                href="https://blog.tripdatabase.com/2023/01/25/pico-search-its-a-special-type-of-search/"
                target="_new"
                rel="nofollow"
              >
                click here for more information
              </a>
            </i>
          </div>
        </Tab>
        <Tab
          eventKey="Advanced2"
          title={
            <div>
              Advanced
              <span className="pro-label">Pro</span>
            </div>
          }
        >
          {props.isPro ? (
            <div className="tab-pane active show">
              <div className="advancedsearch">
                <RecentAdvSearch
                  props={props}
                  getHistoryAdv={getHistoryAdv}
                  advsearchbox={advsearchbox}
                  setAdvSearchbox={setAdvSearchbox}
                  setAdvSearchboxField={setAdvSearchboxField}
                  prepareRecentQuery={prepareRecentQuery}
                />

                <div className="advancedsearch--search-query">
                  <Form onSubmit={handleAdvanced2Submit}>
                    <div className="advancedsearch--search-query--title">
                      <h4>New Search Query</h4>
                    </div>
                    <input
                      type="text"
                      name="advsearchbox"
                      className="form-control"
                      placeholder="Enter search term"
                      value={advsearchbox}
                      onChange={(e) => setAdvSearchbox(e.target.value)}
                    />

                    <div className="advancedsearch--search-query--actions">
                      <p>Search in</p>
                      <select
                        className="btn dropdown-toggle"
                        name="where to search"
                        value={advsearchboxfield}
                        onChange={(e) => setAdvSearchboxField(e.target.value)}
                      >
                        <option value="">Where to search</option>
                        <option value="tb">Document Text</option>
                        <option value="t">Title Only</option>
                      </select>

                      <select
                        id="searchtypeSelect"
                        className="btn dropdown-toggle"
                        onChange={(e) => {
                          setAdvSearchCondition(e.target.value);
                        }}
                        value={advsearchcondition}
                      >
                        <option value="">Search type</option>
                        <option value="AND">All of these words</option>
                        <option value="OR">Any of these words</option>

                        <option value="phrase">Exact Phrase</option>
                      </select>
                      <Button
                        type="submit"
                        className="btn btn-primary search-btn"
                        // href="#"
                        // onClick={handleAdvanced2Submit}
                      >
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
                <div className="advancedsearch--small-print">
                  <a
                    href="https://blog.tripdatabase.com/2023/11/17/how-to-use-advanced-search/"
                    target="_new"
                  >
                    How to use Advanced Search
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <span className="pro-label">
              Pro users only, <a href="/Why">Click here to upgrade </a>
            </span>
          )}
        </Tab>
      </Tabs>
    </div>
  );
};
export default withRouter(Search);
