import React, { useEffect, useRef } from "react";
import _ from "underscore";
// import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";
const PageContentEvidence = ({
  currentPage,
  setCurrentPage,
  data,
  error,
  errorshow,
  setData,
  setErrors,
  setErrorShow,
  clsName,
  meta,
  setMeta,
}) => {
  const contentRef = useRef(null);
  async function fetchPage() {
    axios
      .get(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/EPages"
          : "/EPages",
        {
          params: {
            page: currentPage,
          },
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          let articles = res.data.html;
          articles = articles.replace(
            /evidence\.tripdatabase\.com\/volumes/g,
            "www.tripdatabase.com/volumes"
          );
          setData(articles);
          setMeta(res.data.meta);
        } else {
          const error = new Error(res.error);
          setData("<p>Page not found!</p>");
        }
      })
      .catch((error) => {
        setData("<p>Page not found!</p>");

        // setErrors(error.message);
        // setErrorShow(true);
      });
  }

  useEffect(() => {
    document.body.classList.remove("homepage");
    fetchPage();
    const container = contentRef.current;
    let currentPath = window.location.pathname;

    const appendToUrl = (newPath) => {
      // let segments = currentPath.split("/view/evidence");
      // console.log("current", currentPath);
      if (newPath === "/") {
        currentPath = "/view/evidence";
        newPath = "";
      }
      let updatedPath = `${currentPath}${newPath}`;
      // console.log("nw path", updatedPath);
      if (updatedPath.includes("//")) {
        let p = updatedPath.split("//");
        // console.log("fixed path", p[1]);
        updatedPath = "/view/evidence/" + p[1];
      }
      window.history.pushState(null, "", updatedPath);
    };
    const handleClick = (event) => {
      // Check if the clicked element or any of its parents is an <a> tag
      const target = event.target.closest("a");
      const clickedUrl = target?.href;
      if (!_.isUndefined(clickedUrl)) {
        const url = new URL(clickedUrl);
        let card = clickedUrl.split("#");
        card = card && !_.isUndefined(card[1]) ? card[1] : "";
        const path = url.pathname;

        switch (true) {
          case _.isEmpty(card) &&
            (clickedUrl.includes("www.tripdatabase.com") ||
              clickedUrl.includes("evidence.tripdatabase.com")):
            // {
            event.preventDefault(); // Prevent default anchor click behavior
            // Get the pathname
            // console.log("condition 1", clickedUrl);
            setCurrentPage(path);
            appendToUrl(path);
            let el = document.getElementsByClassName("volume-intro");
            if (el) {
              window.scrollTo({
                top: 200,
                behavior: "smooth",
              });
            }
            break;

          // case !_.isEmpty(window.location.hash) &&
          //   clickedUrl.includes("evidence.tripdatabase.com"):
          //   event.preventDefault();
          //   // console.log("3 this here", clickedUrl, path);

          //   // const path = url.pathname;
          //   setCurrentPage(path);
          //   appendToUrl(path);
          //   break;

          // case !_.isEmpty(window.location.hash) &&
          //   clickedUrl.includes("www.tripdatabase.com/view/evidence"):
          //   event.preventDefault();
          //   // console.log("4 ehere", clickedUrl, path);
          //   setCurrentPage(path);
          //   appendToUrl(path);
          //   break;
          // Added later after url fix
          case (!_.isEmpty(window.location.hash) || !_.isEmpty(card)) &&
            (clickedUrl.includes("www.tripdatabase.com") === true ||
              clickedUrl.includes("evidence.tripdatabase.com") === true ||
              clickedUrl.includes("dev.tripdatabase.com") === true ||
              clickedUrl.includes("labs.tripdatabase.com") === true ||
              clickedUrl.includes("localhost:3000") === true):
            // {
            event.preventDefault();
            // console.log("5 XXXXX here", card, url.pathname, _.isNull(card));

            let elemen = document.getElementById(card); // Get the element by ID (remove #)
            if (elemen) {
              // Scroll to the element
              elemen.scrollIntoView({ behavior: "smooth" });
            }
            break;
          // }
          // inline url no reference to evidence website
          case (!_.isEmpty(window.location.hash) || !_.isEmpty(card)) &&
            clickedUrl.includes("evidence.tripdatabase.com") === true:
            event.preventDefault();
            // console.log(
            //   "6 XXXXX here ",
            //   card,
            //   url.pathname,
            //   window.location.hash || card
            // );

            let element = document.getElementById(card); // Get the element by ID (remove #)
            if (element) {
              // Scroll to the element
              element.scrollIntoView({ behavior: "smooth" });
            }
            break;
        }
      }
    };
    container.addEventListener("click", handleClick);

    // Cleanup the event listener on component unmount
    return () => {
      container.removeEventListener("click", handleClick);
    };
  }, [currentPage]);

  return (
    <div
      ref={contentRef}
      className={clsName}
      dangerouslySetInnerHTML={{ __html: data }}
    ></div>
  );
};

export default PageContentEvidence;
