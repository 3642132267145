import React, { useState, useEffect } from "react";
import { Spinner, OverlayTrigger, Tooltip, Button, Modal, Form } from "react-bootstrap";
import _ from "underscore";
import InfoMdl from "../../components/infoMdl";
import RelatedComponent from "./RelatedComponent";
import PromoComponent from "./PromoComponent";
import URLComponent from "./URLComponent";
import BrokenLinkSvg from "../assets/svg/BrokenLink";
import TwitterSvg from "../assets/svg/Twitter";
import StarSvg from "../assets/svg/Star";
import RelatedSvg from "../assets/svg/Related";
import PyramidSvg from "../assets/svg/Pyramid";
import GuidelinesScore from "./GuidelinesScore";
import GuidelinesMdl from "./GuidelinesMdl";
import BrokenlinkMdl from "./BrokenlinkMdl";
import RobMdl from "./RobMdl";
import RobScore from "./RobScore";
// import SRScore from "./SRScore";

const axios = require("axios");
const dateFormat = require("dateformat");
let guidelines_categories = [
  "(16 OR 18 OR 9 OR 10 OR 4 OR 36)",
  "16",
  "18",
  "9",
  "10",
  "4",
  "36",
];
let ct_categories = ["27"];
/**
 * ResultList
 * @param {array} resultSet
 * @param {int} start
 * @param {boolean} loading
 * @return Results
 */
export const ResultList = ({
  isPro = null,
  resultset,
  publicationset,
  srscoreset,
  start,
  loading,
  publicationSelect,
  searchParams,
  setStarred,
  isStarred,
  snippetshow,
  articleChecked,
  setArticleChecked,
  articleClicked = null,
  setArticleClicked,
  libkeydata = null,
  categorySelect,
  dois,
  currentCategory,
  showRelated,
}) => {
  const [infoshow, setInfoShow] = useState(false);
  const [robshow, setRobShow] = useState(false);
  const [guidelinesshow, setGuidelinesShow] = useState(false);
  const [hasInfo, setInfo] = useState("");
  const [isReported, setReported] = useState([]);
  const [isReportedv, setReportedv] = useState({});
  const [isComplete, setComplete] = useState(false);
  const [enableSmart, setEnableSmart] = useState(false);
  const [selectedGuideline, setSelectGuideline] = useState("");
  const [isHidden, setHidden] = useState(true);
  const [showBrokenLinkModal, setShowBrokenLinkModal] = useState(false);
  const [currentBrokenDoc, setCurrentBrokenDoc] = useState(null);
  const [reporterEmail, setreporterEmail] = useState("");

  const merged = isReported ? isReported : [];
  const starmerged = isStarred ? isStarred : [];
  let count = start + 1; //intalise counter
  let smart_count = 0,
    click_count = 0;
  // Fire this if loading
  // if (loading) {
  //   return (
  //     <div className="pull-center">
  //       <Spinner animation="border" variant="success" />
  //     </div>
  //   );
  // }
  let border = {
    1: {
      cls: "badge badge-evidence-secondary is-active",

      clsdot: "evidence-dot evidence-dot--evidence-secondary",
    },
    2: {
      cls: "badge badge-evidence-key-primary is-active",

      clsdot: "evidence-dot evidence-dot--evidence-key-primary",
    },
    3: {
      cls: "badge badge-evidence-clinical-q-and-a is-active",

      clsdot: "evidence-dot evidence-dot--evidence-clinical-q-and-a",
    },
    4: {
      cls: "badge badge-evidence-all-primary is-active",
      clsdot: "evidence-dot evidence-dot--evidence-all-primary",
    },
    5: {
      cls: "badge badge-evidence-other is-active",
      clsdot: "evidence-dot evidence-dot--evidence-other",
    },
  };

  /**
   * preparekeywords
   */
  function prepareKeywords() {
    let kw = "";
    switch (searchParams.search_type) {
      case "standard":
        kw = searchParams.criteria;
        break;
      case "pico":
        kw =
          "(" +
          searchParams.criteria +
          ")" +
          "(" +
          searchParams.intervention +
          ")" +
          "(" +
          searchParams.comparison +
          ")" +
          "(" +
          searchParams.outcome +
          ")";
        break;
      case "advanced":
        kw =
          "(" +
          searchParams.criteria +
          ") (" +
          (searchParams.anywords ? " " + searchParams.anywords : "") +
          ") (" +
          (searchParams.exactphrase ? " " + searchParams.exactphrase : "") +
          ") (" +
          (searchParams.excludingwords
            ? " " + searchParams.excludingwords
            : "") +
          ")";
        break;
    }
    return kw;
  }
  /**
   * handle Article click
   * @param {*} event
   * @param {*} docId
   */
  const handleArticleClick = (event, document, position) => {
    //Prepare for smartsearch
    var existing = articleClicked ? articleClicked : [];
    if (document) {
      existing.push(document);
    }
    setArticleClicked([...existing]);
    if (!_.isEmpty(articleClicked)) {
      setHidden(document);
      setEnableSmart(false);
    } else {
      setHidden("");
    }

    //Save click stream
    var docs = _.chain(resultset)
      .map(function (value, name) {
        return value.id + ":" + Math.round(value.score, 2);
      })
      .join()
      .value();

    var pos = position - 1;
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/saveDocumentview"
          : "/User/saveDocumentview",
        {
          document_id: document.id,
          documents: docs,
          criteria: JSON.stringify(searchParams),
          keywords: prepareKeywords(),
          activity: "",
          institution_id: null,
          pos: pos,
        },
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * handle starred click
   * @param {*} event
   * @param {*} docId
   */
  const handleStarClick = (event, params) => {
    var docs = _.chain(resultset)
      .map(function (value, name) {
        return value.id + ":" + Math.round(value.score, 2);
      })
      .join()
      .value();

    var pos = event.target.getAttribute("pos") - 1;
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/setStar"
          : "/User/setStar",
        {
          document_id: params.docId,
          documents: docs,
          criteria: JSON.stringify(searchParams),
          keywords: prepareKeywords(),
          activity: params.activity,
          institution_id: null,
          pos: pos,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        //console.log(res);
        return true;
      })
      .catch((error) => {
        setInfo({
          title: "Information",
          message:
            "Access denied - user must be logged in to bookmark articles!",
        });
        setInfoShow(true);
        //window.location.reload(false);
      });
    event.preventDefault();
  };

  /**
   * Report broken link
   * @param {*} event
   * @param {*} params
   */
  const handleReportClick = (event, params) => {
    axios
      .post(
        !_.isUndefined(process.env.REACT_APP_API_URL)
          ? process.env.REACT_APP_API_URL + "/User/reportLink"
          : "/User/reportLink",
        params,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        // console.log(error);
      });
    setShowBrokenLinkModal(false);
    if (event) event.preventDefault();
  };
  //Get publication name
  function getPublication(condition) {
    return publicationset
      .filter((e) => e.id === condition)
      .map((column, index) => {
        return column.publication;
      });
  }

  //Get Guideline score
  function getGuidelinesScore(condition) {
    return publicationset
      .filter((e) => e.id === condition)
      .map((column, index) => {
        return !_.isUndefined(column.guidelines_score)
          ? column.guidelines_score
          : null;
      });
  }
  //Get SR score
  function getSRscore(condition) {
    if (!Array.isArray(srscoreset) || !condition) return [];
    return srscoreset.filter((item) => item.id == condition);
    // return srscoreset
    //   .filter((e) => e.id === condition)
    //   .map((column, index) => {
    //     console.log("cc", column);
    //     return !_.isUndefined(column) ? column : null;
    //   });
  }
  /**
   * Checkbox Click
   * @param {*} event
   * @param {*} params
   */
  const handleCheckbox = (event, document_id) => {
    var existing = articleChecked ? articleChecked : [];
    if (event.target.checked === true) {
      existing.push(document_id);
    } else {
      existing.pop(document_id);
    }

    setArticleChecked([...existing]);
    if (!_.isEmpty(articleChecked)) {
      setHidden(document_id);
      setEnableSmart(false);
    } else {
      setHidden("");
    }
  };
  /**
   * Tweet Link
   * @param {*} e
   * @param {*} id
   * @param {*} text
   */
  function TweetMe(e, id, text) {
    var url = "https://www.tripdatabase.com/Document/" + id;
    window.open(
      "https://twitter.com/intent/tweet?text=" +
        text.substring(0, 100) +
        "  " +
        url +
        "&via=tripdatabase",
      "",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
    );
    e.preventDefault();
  }

  /**
   * Guidelines Modal
   * @param {*} e
   */

  const handleGuidelines = (e, pub_id) => {
    if (!_.isUndefined(pub_id)) {
      setSelectGuideline(pub_id);
    }
    setGuidelinesShow(!guidelinesshow);
    if (e) e.preventDefault();
  };

  const handleRob = (e) => {
    setRobShow(!robshow);
    if (e) e.preventDefault();
  };

  const handleBroken = (e) => {
    setShowBrokenLinkModal(!showBrokenLinkModal);
    if (e) e.preventDefault();
  };

  return (
    <>
      <InfoMdl info={hasInfo} setInfoShow={setInfoShow} show={infoshow} />
      <GuidelinesMdl
        handleClose={handleGuidelines}
        show={guidelinesshow}
        publication_id={selectedGuideline}
      />
      <BrokenlinkMdl
        handleClose={handleBroken}
        show={showBrokenLinkModal}
        document={currentBrokenDoc}
        handleReportClick={handleReportClick}
        reporterEmail={reporterEmail}
        setreporterEmail={setreporterEmail}
      />
      <RobMdl handleClose={handleRob} show={robshow} />

      {resultset.map((documents, idx) => (
        <span key={"res-span" + idx}>
          <div className="result" key={"res" + idx}>
            <div className="result--checkbox">
              <input
                type="checkbox"
                className="chkbox pull-left"
                name="chkArticles"
                onChange={(e) => {
                  handleCheckbox(e, documents.id);
                }}
                checked={articleChecked.includes(documents.id)}
              />
              <span>{count++}</span>
            </div>
            <div className="result--content">
              <URLComponent
                data={documents}
                idx={idx}
                count={count}
                dotClass={border[documents.grade].clsdot}
                isPro={isPro}
                libkeydata={libkeydata}
                linkResolver_url={
                  !_.isNull(documents.linkResolver)
                    ? !_.isUndefined(documents.linkResolver.resolver_url)
                      ? documents.linkResolver.resolver_url
                      : null
                    : null
                }
                handleArticleClick={handleArticleClick}
              />

              <div className="result--taxonomies">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  className="pro-tooltip"
                  overlay={
                    <Tooltip id={"overlay-example" + idx}>
                      A simple tool to help convey the potential robustness of
                      the evidence (for more information{" "}
                      <a
                        href="https://blog.tripdatabase.com/2021/09/26/pyramids-are-back/"
                        target="_blank"
                        rel="nofollow"
                        style={{ color: "yellow" }}
                      >
                        click here
                      </a>
                      )
                    </Tooltip>
                  }
                  delay={{ show: 40, hide: 1800 }}
                >
                  <a
                    href="#"
                    data-toggle="tooltip"
                    data-html="true"
                    title=""
                    onClick={(e) =>
                      categorySelect(
                        e,
                        !_.isUndefined(documents.categories[0])
                          ? documents.categories[0]
                          : 0
                      )
                    }
                  >
                    <PyramidSvg grade={documents.grade}></PyramidSvg>
                  </a>
                </OverlayTrigger>
                <button
                  // style={{ cursor: "pointer" }}
                  className={border[documents.grade].cls}
                  onClick={(e) =>
                    categorySelect(
                      e,
                      !_.isUndefined(documents.categories[0])
                        ? documents.categories[0]
                        : 0
                    )
                  }
                >
                  {documents.category}
                </button>
                <span className="result--taxonomies--date" tabIndex="0">
                  {documents.Date}
                </span>
                <span className="result--taxonomies--pub">
                  <a
                    href="#"
                    onClick={(e) =>
                      publicationSelect(e, documents.Publication_id)
                    }
                  >
                    {getPublication(documents.Publication_id)}
                  </a>
                </span>
                <GuidelinesScore
                  score={getGuidelinesScore(documents.Publication_id)}
                  publication_id={documents.Publication_id}
                  handleGuidelines={handleGuidelines}
                  category_guideline={
                    currentCategory
                      ? guidelines_categories.includes(
                          currentCategory.toString()
                        )
                      : false
                  }
                />
                <RobScore
                  score={documents.prob_low_rob}
                  handleRob={handleRob}
                  category_ct={
                    currentCategory
                      ? ct_categories.includes(currentCategory.toString())
                      : false
                  }
                />
                {/* <SRScore
                  score={getSRscore(documents.id)}
                  handleRob={handleRob}
                  category_ct={
                    currentCategory
                      ? ct_categories.includes(currentCategory.toString())
                      : false
                  }
                /> */}
              </div>

              {snippetshow && (
                <div
                  className="result--snippet"
                  dangerouslySetInnerHTML={{ __html: documents.snippet }}
                ></div>
              )}

              <div className="result--actions">
                <ul>
                  <li>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      className="pro-tooltip"
                      overlay={
                        <Tooltip id={`tooltip-top-twitter` + idx}>
                          Share on Twitter
                        </Tooltip>
                      }
                    >
                      <a
                        className="result-action"
                        href="#"
                        data-toggle="tooltip"
                        data-original-title="Share on Twitter"
                        data-html="true"
                        title=""
                        onClick={(e) =>
                          TweetMe(e, documents.id, documents.Title)
                        }
                      >
                        <TwitterSvg />
                      </a>
                    </OverlayTrigger>
                  </li>
                  <li>
                    {isStarred.includes(parseInt(documents.id, 10)) === true ? (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        className="pro-tooltip"
                        overlay={
                          <Tooltip id={`tooltip-top-bookmark` + idx}>
                            Bookmark
                          </Tooltip>
                        }
                      >
                        <a
                          href="#"
                          className="result-action result-action__active"
                          data-toggle="tooltip"
                          data-html="true"
                          title=""
                          data-original-title="Bookmark"
                          pos={count}
                          activity={1}
                          onClick={(e) => {
                            starmerged.pop(parseInt(documents.id, 10));
                            isStarred.pop(parseInt(documents.id, 10));
                            setStarred(starmerged);
                            //Dirty hack
                            setReportedv((prevState) => ({
                              ...prevState,
                              id: documents.id,
                            }));
                            handleStarClick(e, {
                              docId: documents.id,
                              activity: -1,
                              count: count,
                            });
                          }}
                        >
                          <StarSvg />
                        </a>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        className="pro-tooltip"
                        overlay={
                          <Tooltip id={`tooltip-top-bookmark` + idx}>
                            Bookmark
                          </Tooltip>
                        }
                      >
                        <a
                          href="#"
                          className="result-action"
                          pos={count}
                          activity={1}
                          onClick={(e) => {
                            starmerged.push(parseInt(documents.id, 10));
                            setStarred(starmerged);
                            //Dirty hack
                            setReportedv((prevState) => ({
                              ...prevState,
                              id: documents.id,
                            }));
                            handleStarClick(e, {
                              docId: documents.id,
                              activity: 1,
                              count: count,
                            });
                          }}
                        >
                          <StarSvg />
                        </a>
                      </OverlayTrigger>
                    )}
                  </li>
                  {/* {isPro && ( */}
                  <li>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      className="related-tooltip"
                      overlay={
                        <Tooltip id={`tooltip-top-related` + idx}>
                          Related Articles
                        </Tooltip>
                      }
                    >
                      <a
                        href="#"
                        className="result-action"
                        onClick={(e) => {
                          showRelated(documents);
                          e.preventDefault();
                        }}
                      >
                        <RelatedSvg />
                      </a>
                    </OverlayTrigger>
                  </li>
                  {/* )} */}
                </ul>
                <a
                  href="!/#"
                  className="broken-link"
                  onClick={(e) => {
                    setreporterEmail("");
                    merged.push(documents.id);
                    setReported(merged);
                    // Dirty hack
                    setReportedv((prevState) => ({
                      ...prevState,
                      id: documents.id,
                    }));
                    // handleReportClick(e, {
                    //   docId: documents.id,
                    //   url: documents.Url,
                    //   title: documents.Title,
                    // });
                    setCurrentBrokenDoc(documents);
                    setShowBrokenLinkModal(true);
                    e.preventDefault();
                  }}
                >
                  {isReported.includes(documents.id) === true ? (
                    <>
                      <i className="fa fa-thumbs-up text-success"></i>
                      <p className="text-success"> Thank You! </p>
                    </>
                  ) : (
                    <BrokenLinkSvg text="Broken Link?" />
                  )}
                </a>
              </div>
            </div>
          </div>
          {isPro == false && count == 3 && (
            <PromoComponent data={{ isPro: isPro, count: count }} />
          )}
        </span>
      ))}
    </>
  );
};
